<template>
    <div class="privacyPolicy">
        <div class="privacyPolicy-title"><h1>cxskins隐私政策</h1>
        <p>根据法律成立并运营的公司“cxskins”（以下简称“公司”）在本文中阐明了cxskins.com服务（以下简称“ cxskins.com”）使用条款和条件（以下简称“服务”）。</p>
        </div>
        
        <div class="privacyPolicy-main">
            <div>
                <p><span>引言</span></p>
                <p><span>cxskins非常重视用户的隐私和个人信息保护。为了您可以完整地使用我们的产品与/或服务，cxskins将依据《中华人民共和国网络安全法》、《信息安全技术 个人信息安全规范》以及其他相关法律法规和技术规范收集和使用您的相关个人信息。本《隐私权保护政策》将说明在您使用我们的产品与服务时，cxskins如何收集、使用、保存、保护、共享和转让这些信息，请在使用我们的服务前，仔细阅读并了解本隐私权保护政策。请仔细阅读本《隐私权保护政策》并确定了解我们对您个人信息的处理规则。阅读、使用cxskins产品/服务过程中，如您有任何疑问，可通过本隐私权保护政策中列明的cxskins联系方式与我们联系，如您不同意本隐私权保护政策中的任何条款，您应立即停止访问和使用相应产品服务。</span></p>
                <p style="margin-top:20PX"></p>
                <p><span class="strong">一般规定</span></p>
                <p><span>1. 此文本是商业方（我方）与服务的最终用户（您）之间的公开协议（要约）。本协议的条款相同，对本服务的所有用户均具有约束力。根据公认的术语，此协议被视为软件产品的所有者与最终用户之间的许可协议。</span></p>
                <p><span>2. 公司根据本协议向用户提供访问服务的权利以及使用服务的权利，仅供个人使用和娱乐目的。公司仅允许个人使用服务。</span></p>
                <p><span>3. 接受和执行此条款和条件是使用服务的前提条件。不同意这些条款和条件，不接受并拒绝遵守这些条款和条件的用户，无权使用服务，并有义务离开本网站。</span></p>
                <p><span>4. 如果您在您所在国家/地区的法律下没有完全的法律行为能力，请立即离开此站点。公司拒绝您使用此服务的可能性。</span></p>
                <p><span>5. 如果您沉迷于游戏，我们敦促您不要玩！您不会因为玩游戏而错过学校或工作，不能为了玩游戏而借钱或出售财产，不能花掉最后的钱，并且因为玩游戏而自杀的想法肯定是荒谬的。请咨询您信任的心理学家-您可以找到帮助。</span></p>
                <p><span>6. 对于玩家因损失或处于影响状态而对自己或第三方造成的死亡或人身伤害，公司概不负责。</span></p>
                <p><span>7. 在使用服务之前，您必须完全阅读并理解此条款和条件。如果您不理解本文中的任何内容，请在使用服务之前与我们的支持团队联系。此条款和条件影响您的合法权利和义务。误会不能免除法律责任。</span></p>
                <p><span>8. 这些条款和条件约束服务的访问和使用，包括服务提供的任何内容，功能和服务。通过单击“同意”按钮和/或将服务用作访客和/或注册用户，即表示您无条件同意本优惠中列出的条款和条件。这些条款和条件在您访问网站（访问服务）后立即生效。</span></p>

                <p style="margin-top:20PX"></p>
                <p><span class="strong">公司的保证和责任</span></p>
                <p><span>9. 公司以“原样”和“可用”为基础向用户提供服务-具有存在或可能出现的所有属性，优点和缺点。这意味着公司对用户对服务质量和属性不承担任何责任，并且不提供任何保证。使用服务会带来财务损失的风险。用户参加游戏时需自担风险。</span></p>
                <p><span>10. 不论情况如何和我们的努力，我们都不保证客户服务将不间断、及时、无误地进行，或保证缺陷得到纠正。</span></p>
                <p><span>11. 我们可能会不时需要更新，重置，暂时中断或关闭服务的某些或所有部分。这些操作均可能导致您无法使用服务。如果服务或服务的任何方面由于任何原因被中断或无法使用，我们对您不承担任何责任。</span></p>
                <p><span>12. 本公司在任何时候不经解释，不另行通知，不对您承担任何责任的情况下，全权保留绝对和无限的权利：</span></p>
                <p style="padding-left:30px"><span>· 修改些条款和条件的任何规定。所有更改从在服务网站上发布之日起生效；</span></p>
                <p style="padding-left:30px"><span>· 拒绝访问服务；</span></p>
                <p style="padding-left:30px"><span>· 暂时阻止和/或完全删除账户，包括拒绝用户访问服务；</span></p>
                <p style="padding-left:30px"><span>· 索取任何KYC文件，限制访问和付款，直到充分确认为止；</span></p>
                <p style="padding-left:30px"><span>· 终止服务的任何组成部分或整个服务。</span></p>

                <p style="margin-top:20PX"></p>
                <p><span class="strong">通知和变更</span></p>
                <p><span>13. 公司对该服务和服务组件的所有更改的通知均由公司在服务网站上发布。我们不会单独通知用户更改。用户（您）应独立熟悉所有更新。更改的有效性不取决于用户的知识。</span></p>
                <p><span>14. 如果您不同意这些条款和条件的更改，则应独立停止使用服务。公司不接受用户对这些条款和条件的更改存在异议的主张。同样，此类更改的后果也不能成为用户主张的基础。</span></p>
                <p><span>15. 未提供用户主动对此优惠的更改。此优惠在删除用户账户后失效。</span></p>
                <p><span>16. 用户只能在以下第一个事件之前拒绝此提议，而不会造成任何后果和义务：余额中的第一笔存款和/或第一款游戏。</span></p>

                <p style="margin-top:20PX"></p>
                <p><span class="strong">用户/玩家</span></p>
                <p><span>17. 服务的最终用户是玩家。有关识别玩家，访问和使用服务所需的所有用户信息，以及有关服务网站上所有玩家活动的信息，都存储在账户中，该账户由服务在首次创建时创建用户的活动（注册）。</span></p>
                <p><span>18. 用户（您）承诺（其中包括）向公司和/或服务提供有关他的可靠信息。提供虚假信息的后果的所有风险和责任均由用户（您）承担。</span></p>
                <p><span>19. 保护本协议双方的个人数据的问题由隐私政策文件另行规定。</span></p>
                <p><span>20. 我们使用localStorage。 localStorage由localStorage政策单独管理。</span></p>
                <p><span>21. 用户有权被遗忘-根据他的请求，服务将删除该账户及其包含的有关该用户的所有信息。</span></p>
                <p><span>22. 该服务有权出于广告目的显示有关大笔奖金的信息，并相应地显示获奖者的信息。为此，不需要获胜者的同意，也不需向获胜者支付任何费用。</span></p>
                <p style="margin-top:20PX"></p>
                <p><span class="strong">财务关系</span></p>
                <p><span>23. 该服务不是银行机构（存储，存款和储蓄存款的地方，以及汇款服务）。参与游戏过程所需的玩家运营资金存放在安全的地方，但是，我们建议您不要将资金长时间存放在账户中。</span></p>
                <p><span>24. 用户账户中的资金充值是通过收款代理商（付款系统）进行的，该收款系统不受公司管理。玩家与支付系统之间的关系不受此条款和条件的约束。公司不接受玩家对与支付系统活动有关的问题的索赔。</span></p>
                <p><span>25. 禁止从他人的钱包中存入资金。使用第三方资金的责任完全由用户承担。公司不接受第三方关于用户存入或提取的资金的索赔。此类损失应由用户自己赔偿，而无需公司和服务部门的参与。</span></p>
                <p><span>26. 公司将努力为符合本服务条款和KYC / AML要求的所有服务用户及时处理交易。</span></p>
                <p><span>27. 在某些情况下，需要进行手动交易处理，这需要花费大量时间-有时需要几天。您应该以谅解的态度对待它。</span></p>
                <p><span>28. 如果关闭账户，余额将退还给您。如果有理由声称您非法收取了该账户的资金，则这些资金将记入借方且不予支付。</span></p>
                <p><span>29. 我们不会在OFAC（美国外国资产控制办公室）的任何授权国家/地区进行任何运营或以任何方式参与交易。</span></p>
                <p><span>30. 除其他外，玩家同意对以下事项承担全部责任：</span></p>
                <p style="padding-left:30px">· 意外做出的动作或决定；<span></span></p>
                <p style="padding-left:30px">· 因玩家使用的设备故障而造成的损失；<span></span></p>
                <p style="padding-left:30px">· 因将资金转移给其他玩家，给予他们特权或贷款而导致玩家损失；<span></span></p>
                <p style="padding-left:30px">· 玩家输入/提取饰品的地址有误；<span></span></p>
                <p style="padding-left:30px">· 玩家访问服务所产生的费用（包括互联网费用）；<span></span></p>
                <p style="padding-left:30px">· 对于任何其他损失，由于本服务是按“原样”提供，而公司未作任何保证。<span></span></p>
                <p><span>31. 游戏的结果取决于机会。我们在制定游戏算法时遵循可接受的措施，因此，我们认为游戏过程是诚实的。您关于游戏过程中不诚实行为的任何理论都不能成为取消游戏结果的基础。此外，您的生活状况，疾病或在任何药物的影响下逗留都不能成为取消游戏结果的依据。</span></p>
                <p><span>32. 在此类游戏的“服务”网站上，针对服务的特定游戏的概率在充分参与游戏过程的情况下进行了阐述。如果您什么都不懂，请在下注之前与我们的支持团队联系。</span></p>
                <p><span>33. 公司不是玩家的税务代理，也不对玩家的财务义务负责。每个人都独立计算并支付他应得的税款。每个人都独立提交适当的纳税报告。</span></p>
                <p><span>34. 本服务不提供各种性质的金融资产交换服务。例如，您不能将法定货币存入服务，而提取加密货币；反之亦然。</span></p>

                <p style="margin-top:20PX"></p>
                <p><span class="strong">禁止与限制</span></p>
                <p><span>35. 禁止将服务用于以下人员：</span></p>
                <p style="padding-left:30px"><span>· 在其他任何游戏网站或游戏对象上被阻止；</span></p>
                <p style="padding-left:30px"><span>· 包括在任何在线游戏运营商的限制列表中；</span></p>
                <p style="padding-left:30px"><span>· 禁止参加国家规定的游戏；</span></p>
                <p style="padding-left:30px"><span>· 未成年以下，以及国家法律行为能力有限的人；</span></p>
                <p style="padding-left:30px"><span>· 严重或系统地违反了此条款和条件的要求以及普遍接受的网络游戏道德原则；</span></p>
                <p style="padding-left:30px"><span>· 未接受或不同意本要约规定的人（全部或部分）；</span></p>
                <p style="padding-left:30px"><span>· 被正式诊断为“游戏瘾”的人；</span></p>
                <p style="padding-left:30px"><span>· 向服务提供虚假信息的人（直到情况得到纠正）。</span></p>
                <p style="padding-left:30px"><span>· 如果有与您有关的任何情况，请停止使用服务并通知我们。</span></p>
                <p><span>36. 除此优惠的其他条件外，公司明确禁止：</span></p>
                <p style="padding-left:30px"><span>· 使用服务作为法律实体（商业和非营利组织）的最终用户；</span></p>
                <p style="padding-left:30px"><span>· 将服务用于玩家的个人娱乐以外的任何其他目的。</span></p>
                <p><span>37. 禁止以任何方式复制或复制本服务或其组件（包括商标）。禁止未经授权访问服务的数据库，站点和组件，以及复制，模仿和使用商标。在此类行动中被发现的人将被追究刑事责任，并有义务赔偿公司造成的损失。</span></p>
                <p><span>38. 禁止使用在使用服务期间获得的信息对公司声誉造成损害。散布欺诈性信息的人将受到法律起诉。</span></p>
                <p><span>39. 禁止使用与玩家，服务，公司及其员工有关的冒犯性词语。您将被因为侮辱而遭禁止，账户资金将被不可撤销地注销。</span></p>
                <p><span>40. 除了众所周知的事项，我们还考虑并禁止在我们的网站上进行明显欺诈的行为：</span></p>
                <p style="padding-left:30px"><span>· 玩家使用多个账户来规避此条款和条件及会员计划的规则所建立的限制；</span></p>
                <p style="padding-left:30px"><span>· 向第三方提供您的账户访问权限；</span></p>
                <p style="padding-left:30px"><span>· 使用自动脚本；</span></p>
                <p style="padding-left:30px"><span>· 向某人索要钱款，包括信贷和免费；</span></p>
                <p style="padding-left:30px"><span>· 向使用本服务的用户（或其他人）使用其会员促销代码的奖金；</span></p>
                <p style="padding-left:30px"><span>· 与玩家（或其他人）沟通，故意将自己代表为公司/服务的员工；</span></p>
                <p style="padding-left:30px"><span>· 散布垃圾邮件，未经授权的广告以及令人反感的和/或虚假的信息。</span></p>
                <p><span>41. 除此条款和条件的其他条款外，我们禁止在网站上进行以下行为：</span></p>
                <p style="padding-left:30px"><span>· 对cxskins服务器发起攻击或以其他方式破坏cxskins；</span></p>
                <p style="padding-left:30px"><span>· 诽谤，滥用，骚扰，威胁或以其他方式侵犯他人的合法权利；</span></p>
                <p style="padding-left:30px"><span>· 利用csgoSkin或csgoSkin规则中的漏洞（请报告）；如恶意，滥用漏洞对本网造成损失者，该网站有权追回该损失，并有权终止非法所得交易。并追究其法律责任。</span></p>
                <p style="padding-left:30px"><span>· 以误导他人为目的创建虚假身份。</span></p>
                <p><span>42. 禁止客户将其账户转让或出售给他人。禁止包括转让任何类型的任何有价资产，例如但不限于与这些资产相关的账户所有权，奖金，存款，投注，权利和/或债权，无论是合法，商业或其他形式。但是，对上述转让的禁止还包括但不限于与受托人或任何其他第三方，公司，自然人或法人，基金会和合伙人合作进行的产权负担，质押，转让，使用，交易，经纪，抵押和/或赠与/或任何形式的关联。</span></p>

                <p style="margin-top:20PX"></p>
                <p><span class="strong">正式规则和特别规定</span></p>
                <p><span></span>43. 在法律确定的术语中，服务不是赌博。该陈述是基于这样的事实，即在游戏过程中，我们会玩专门包含虚拟游戏内容（皮肤）的战利品，而如何在游戏外部使用该内容与我们无关。该服务可以赢得胜利。皮肤不是电子货币，也不是其他类型的金融或有形资产。玩家独立且仅在游戏结束后使用并处理赢得的皮肤。与虚拟游戏内容的交换和/或转让有关的可能的玩家交易不受此条款和条件的约束。</p>
                <p><span></span>44. 该服务可能包含指向第三方服务和第三方站点的超链接。该公司不控制这些服务和站点，因此不对它们负责。使用此类第三方服务和第三方站点时要小心-此类使用（包括向其传输信息）的所有后果完全取决于您。</p>
                <p><span></span>45. 对您的用户名，密码和其他任何会损害账户功能的信息保密，完全取决于您。永远不要告诉任何人，甚至我们的客服团队您的密码。我们绝不会要求您告诉我们密码，如果您收到了这样的请求，请相信他们是骗子。</p>
                <p><span></span>46. 本文的章节标题仅出于可读性考虑，并不影响条款和条件的法律含义。</p>
                <p><span></span>47. 本协议的法律和相关文本仅是发布在我们网站上的文本。该条约文本的所有其他解释都是非法的。对条文的无知或误解不是免除责任的理由。</p>
                <p><span></span>48. 如果主管当局在任何程度上使本要约的任何规定无效，则主管当局的这一决定的后果仅适用于对该决定提出申诉的人。本要约任何部分的无效均不会导致该要约整体无效。要约的无效部分必须由公司修改，以使其与主管当局的决定保持一致。</p>
                <p><span></span>49. 服务的所有权利（包括版权，有形和无形的）均归公司所有，在任何情况下均无条件保留。放弃对服务的任何权利不是此条款和条件的主题。</p>
                <p><span></span>50. 公司可以将其任何权利转让给第三方。用户无权根据本协议转让其权利。</p>
                <p><span></span>51. 我们拒绝任何形式的欺诈。如果您知道有人使用我们的网站进行欺诈，请告诉我们。</p>
                <p><span></span>52. 任何违反此条款和条件的行为均需承担责任。对违法者实施制裁并不能免除他纠正违法行为的义务，将来也不允许这样做。</p>
                <p><span></span>53. 与此项优惠有关的所有争议仅通过谈判解决。仅在强制性调解程序之后且无法达成协议时才允许提起诉讼。与该报价有关的争议不受商业仲裁规则的约束；所有有争议的问题均受民法管辖（在服务提供商和消费者之间）。</p>
                <p><span></span>54. 与本协议主题有关的，不受本协议文本约束的所有问题，均以公认的商业惯例和管辖此类法律关系的法律为基础进行管理。</p>
                
                <p style="margin-top:20PX"></p>
                <p><span  class="strong">与cxskins联系</span></p>
                <p><span>如果您对本隐私权保护政策有任何疑问、意见或建议，您可以通过QQ客服联系”{{this.$store.state.webdata.qq}}”, cxskins将尽快在验证您的用户身份后及时予以回复。我们通常会在2个工作日答复</span></p>
            </div>
        </div>
    </div>
</template>


<script>
export default {
   components:{
   },
   mounted(){
       document.documentElement.scrollTop = 0;
   },
}
</script>

<style scoped lang="less">
.privacyPolicy{
    font-size: 14PX;
}
.privacyPolicy-title{
    text-align: center;
    padding: 30px 0;
    h1{
        font-size: 35px;
    }
}
.privacyPolicy-main{
    width: 70%;
    margin: 0 auto;
    padding: 0 20px;
    padding-bottom: 60px;
}
.strong{
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
}
@media screen and (max-width: 800px) {
    .privacyPolicy-main{
        width: 100%;
    }
}
</style>